import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PaginationArrow from "./Pagination/PaginationArrow";

const CarouselItem = ({ featureLink, blogID, blog }: any) => {
  return (
    <Link
      to={"/" + featureLink + "/" + blogID}
      className={`h-full w-full sm:items-start sm:justify-start px-2 hover:no-underline hover:text-current`}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: blog,
        }}
        className="text-xs md:text-sm flex flex-col text-center"
      />
    </Link>
  );
};

const Carousel = ({ blogData, timer, viewData, classes, anchorID }: any) => {
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex: any) => {
    if (newIndex < 0) {
      newIndex = blogData?.length - 1;
    } else if (newIndex >= blogData?.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, timer);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  if (blogData === undefined) {
    return <>Still loading...</>;
  }

  return (
    <div
      id={anchorID}
      style={{
        background: viewData.config?.bgColor,
        color: viewData.config?.textColor,
      }}
      className="w-full h-full flex flex-col justify-center items-center text-white"
    >
      <h1 className="w-full font-bold my-4 text-2xl sm:text-3xl md:text-4xl text-center flex flex-col justify-center items-center">
        {viewData.title.toUpperCase()}
        <div
          style={{ borderColor: viewData?.config?.hrColor }}
          className="mt-3 mb-2 border-b-4 w-60"
        />
      </h1>
      <div className="carousel w-full">
        <div
          className={`w-full md:h-80 flex flex-col-reverse justify-center items-center mx-auto mb-4`}
        >
          <div
            className={`h-full w-full max-w-6xl text-left px-3 flex flex-col-reverse ${
              viewData.config?.image?.position === "right"
                ? "sm:flex-row"
                : "sm:flex-row-reverse"
            } justify-evenly items-center`}
          >
            <div className="h-full flex flex-col space-y-4 max-w-4xl flex-1 items-center">
              {blogData.map((child: any, index: any) => {
                return (
                  index === activeIndex && (
                    <CarouselItem
                      featureLink={viewData.config?.feature}
                      blogID={child["_id"]["$id"]}
                      blog={child?.content}
                    />
                  )
                );
              })}
              <div className="flex flex-col sm:flex-row items-center md:justify-start space-y-4 sm:space-x-8 sm:space-y-0">
                <PaginationArrow
                  onClickLeft={() => {
                    updateIndex(activeIndex - 1);
                    setPaused(true);
                  }}
                  onClickRight={() => {
                    updateIndex(activeIndex + 1);
                    setPaused(false);
                  }}
                  activeIndex={activeIndex}
                  paginationColor={viewData.config?.pagination}
                />
                {viewData.config.viewAll && (
                  <Link
                    to={"/" + viewData.config.feature}
                    className={"hover:text-current  "}
                  >
                    <button
                      style={{
                        background: viewData.config?.bgColor,
                        borderColor: viewData.config?.textColor,
                      }}
                      type="button"
                      className="rounded-full hover:no-underline h-full px-8 py-2 border-2 mx-auto text-sm font-medium shadow-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    >
                      <div
                        className={
                          "uppercase w-full text-center xl:text-lg tracking-tighter"
                        }
                      >
                        {viewData.config?.button?.text}
                      </div>
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="flex-1 3xl:flex-none flex justify-center h-full mb-2 md:mb-0">
              <Link
                to={
                  "/" +
                  viewData.config?.feature +
                  "/" +
                  blogData[activeIndex]["_id"]["$id"]
                }
                className="h-full w-full"
              >
                <img
                  className={`${classes?.imageRatio} text-white rounded-md max-h-96 h-full mx-auto object-contain`}
                  src={blogData[activeIndex]?.images[0]?.large}
                  alt="Testimonial"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function BlogCarousel(props: any) {
  const { viewData, classes, anchorID, manifestData } = props;

  const [blogData, setBlogData] = useState<any>();

  const timer = viewData.config.timer;
  const blogURL = manifestData["features"][viewData.config["feature"]]["url"];

  // fetch blog data
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(blogURL);
      // convert data to json
      const json = await response.json();
      setBlogData(json);
    };

    fetchData().catch(console.error);
  }, [blogURL]);

  return (
    <Carousel
      blogData={blogData}
      timer={timer}
      viewData={viewData}
      classes={classes}
      anchorID={anchorID}
    />
  );
}
