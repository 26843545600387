import React from "react";

export default function PaginationArrow(props) {
  let { onClickLeft, onClickRight, paginationColor } = props;
  return (
    <div className="flex items-center justify-between">
      <div className="sm:flex-1 sm:flex items-center sm:justify-between">
        <div className="justify-items-center">
          <nav
            className="relative z-0 inline-flex shadow-sm space-x-4"
            aria-label="Pagination"
          >
            <button
              style={{
                background: paginationColor.outerColor,
                color: paginationColor.innerColor,
              }}
              className="rounded-full relative inline-flex items-center px-2 py-2"
              onClick={onClickLeft}
              aria-label="left button"
            >
              <svg
                className="h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              className="rounded-full relative inline-flex items-center px-2 py-2 "
              onClick={onClickRight}
              style={{
                background: paginationColor.outerColor,
                color: paginationColor.innerColor,
              }}
              aria-label="right button"
            >
              <svg
                className="h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
