import React from "react";

export default function Label(props: any) {
  const { paddingTop, paddingBottom, alignment, colorScheme, labelText } =
    props.labelConfig;
  return (
    <div
      id={props.anchorID}
      style={{
        paddingTop: paddingTop + "px",
        paddingBottom: paddingBottom + "px",
        textAlign: alignment,
        backgroundImage: colorScheme
          ? colorScheme
          : "linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7)",
        backgroundColor: "#f0c44c",
      }}
      className={`${props.labelConfig?.additionalTailwindStyles} flex flex-col gap-3 items-center px-4 lg:px-16`}
      dangerouslySetInnerHTML={{ __html: labelText }}
    />
  );
}
