import { Col, Row } from "reactstrap";
import "./TwoColumnView.css";

export default function TwoColumnView(props: any) {
  const { manifestData, layoutConfig, classes, anchorID } = props;
  return (
    <div className="w-full text-center" style={{ backgroundColor: "#223355" }}>
      <h1
        className="text-white font-semibold text-xl sm:text-4xl pt-4 mb-2"
        dangerouslySetInnerHTML={{ __html: layoutConfig.header }}
      />
      <hr
        className="mx-auto w-32 mt-0 py-0.5"
        style={{
          background: "#e7c447",
        }}
      />
      <Row
        id={anchorID}
        style={{ backgroundColor: "#223355" }}
        className={layoutConfig?.additionalTailwindStyles}
      >
        <Col
          className="TwoColumnViewColOne"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          xs={10}
          sm={6}
        >
          <img
            className="object-contain max-h-80"
            src={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[layoutConfig.img]["url"]
                : ""
            }
            alt={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[layoutConfig.img]["altText"]
                : ""
            }
          />
        </Col>
        <Col className="TwoColumnViewColTwo" xs={10} sm={6}>
          <p
            className="text-md sm:text-lg xl:text-2xl text-white font-semibold text-left"
            dangerouslySetInnerHTML={{ __html: layoutConfig.text }}
          />
        </Col>
      </Row>
    </div>
  );
}
