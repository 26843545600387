import OCVContactsEntry from "./OCVContactsEntry";
import { Col } from "reactstrap";

interface ContactFunction {
  search: string;
  contacts: { [key: string]: Array<object> };
  currentTab: string;
  contactsPerPage: Array<object>;
}

// if the search field IS empty, then this function returns a spliced contacts array (for pagination purposes)
// if the search IS NOT empty, then this function returns the entire contacts array
// this is because
function Contacts(
  search = "",
  contacts: object[],
  contactsPerPage: object[]
): Array<object> {
  if (search.trim().length === 0) {
    return contactsPerPage;
  } else {
    return contacts?.filter((contact: any) =>
      contact?.title?.toLowerCase().includes(search)
    );
  }
}

export const RenderContacts = ({
  search,
  contacts,
  currentTab,
  contactsPerPage,
}: ContactFunction) => {
  return (
    <Col xs={11} md={10} lg={8} className="w-full p-0">
      {Contacts(search, contacts[currentTab], contactsPerPage)?.map(
        (contact: { [key: string]: any }, index: number) => (
          <OCVContactsEntry key={contact?.title + index} entry={contact} />
        )
      )}
    </Col>
  );
};
