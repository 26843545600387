import React from "react";
import TopBar from "../../MainComponents/TopBar/TopBar";
import NavBar from "../../MainComponents/NavBar/NavBar";
import Slider from "../../MainComponents/Slider/Slider";
import FeatureBar from "../../MainComponents/FeatureBar/FeatureBar";
import SummaryImage from "../../MainComponents/SummaryImage/SummaryImage";
import Summary from "../../MainComponents/Summary/Summary";
import Footer from "../../MainComponents/Footer/Footer";
import FormFooter from "../../MainComponents/FormFooter/FormFooter";
import Label from "../../MainComponents/Label";
import ImageBar from "../../MainComponents/ImageBar";
import TwoColumnView from "../../MainComponents/TwoColumnView/TwoColumnView";
import HorizontalSublayout from "../../MainComponents/HorizontalSublayout/HorizontalSublayout";
import SocialBar from "../../MainComponents/SocialBar/SocialBar";
import PageView from "../../MainComponents/PageView/PageView";
import DownloadOurApp from "../../MainComponents/DownloadOurApp/DownloadOurApp";
import OCVButtonBar from "../../OCVFeatures/OCVButtonBar/OCVButtonBar";
import OCVBlogCardWidget from "../../OCVFeatures/OCVBlogCardWidget/OCVBlogCardWidget";
import BlogCarousel from "../../OCVFeatures/OCVBlogCarousel/OCVBlogCarousel";
import OCVBlogGridWidget from "../../OCVFeatures/OCVBlogGridWidget/OCVBlogGridWidget";
import BlogFAQ from "../../OCVFeatures/OCVBlogFAQ/OCVBlogFAQ";
import NewsDigest from "../../MainComponents/NewsDigest/NewsDigest";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";

export default function OCVContainer({ manifestData, classes, config }: any) {
  //manifestData!: OCVManifestModel;

  function buildMainPage(views: any, styles: any, manifestData: any) {
    let mainPage: JSX.Element[] = [];
    for (let i = 0; i < views?.length; i++) {
      let view = manifestData["views"][views[i]];

      switch (view["type"]) {
        case "topbar":
          mainPage.push(
            <TopBar
              key={views[i]}
              classes={styles}
              manifestData={manifestData}
              topbarConfig={view["config"]}
              anchorID={views[i]}
            />
          );
          break;
        case "label":
          mainPage.push(
            <Label
              key={views[i]}
              labelConfig={view["config"]}
              anchorID={views[i]}
            />
          );
          break;
        case "webNavbar":
          mainPage.push(
            <NavBar
              key={views[i]}
              classes={styles}
              manifestData={manifestData}
              navbarConfig={view["config"]}
              anchorID={views[i]}
            />
          );
          break;
        case "slider":
          mainPage.push(
            <Slider key={views[i]} sliderConfig={view["config"]} />
          );
          break;
        case "video":
          mainPage.push(
            <OCVVideoPlayer key={views[i]} config={view["config"]} />
          );
          break;
        case "imageBar":
          mainPage.push(
            <ImageBar
              key={views[i]}
              classes={styles}
              imageBarConfig={view["config"]}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "featureBar":
          mainPage.push(
            <FeatureBar
              key={views[i]}
              manifestData={manifestData}
              features={manifestData["features"]}
              classes={styles}
              featureBarConfig={view["config"]}
              featureBar={view}
              anchorID={views[i]}
            />
          );
          break;
        case "footer":
          mainPage.push(
            <Footer
              key={views[i]}
              classes={styles}
              manifestData={manifestData}
              footerConfig={view["config"]}
              anchorID={views[i]}
            />
          );
          break;
        case "formFooter":
          mainPage.push(
            <FormFooter
              key={views[i]}
              manifestData={manifestData}
              footerConfig={view["config"]}
              formConfig={view}
              anchorID={views[i]}
            />
          );
          break;
        case "twoColumnView":
          mainPage.push(
            <TwoColumnView
              key={views[i]}
              classes={styles}
              manifestData={manifestData}
              layoutConfig={view["config"]}
              anchorID={views[i]}
            />
          );
          break;
        case "horizontalLayout":
          mainPage.push(
            <HorizontalSublayout
              key={views[i]}
              classes={styles}
              manifestData={manifestData}
              config={view["config"]}
              anchorID={views[i]}
            />
          );
          break;
        case "summaryImage":
          mainPage.push(
            <SummaryImage
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "summary":
          mainPage.push(
            <Summary
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "socialBar":
          mainPage.push(
            <SocialBar
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "pageView":
          mainPage.push(
            <PageView
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "downloadOurApp":
          mainPage.push(
            <DownloadOurApp
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "newsDigest":
          mainPage.push(
            <NewsDigest
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "blogCarousel":
          mainPage.push(
            <BlogCarousel
              key={views[i]}
              classes={styles}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "buttonBar":
          mainPage.push(
            <OCVButtonBar
              key={views[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={views[i]}
            />
          );
          break;
        case "blogGrid":
          mainPage.push(
            <OCVBlogGridWidget
              key={views[i]}
              viewData={view}
              anchorID={views[i]}
            />
          );
          break;
        case "blogFAQ":
          mainPage.push(
            <BlogFAQ
              key={views[i]}
              viewData={view}
              link={view.url}
              anchorID={views[i]}
            />
          );
          break;
        case "blogCard":
          mainPage.push(
            <OCVBlogCardWidget
              key={views[i]}
              viewData={view}
              anchorID={views[i]}
            />
          );
          break;
        default:
          break;
      }
    }
    return mainPage;
  }

  return <div>{buildMainPage(config?.order, classes, manifestData)}</div>;
}
